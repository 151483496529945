enum ErrorCodes {
  ALREADY_STARTED = 'ALREADY_STARTED',
  BATTLETRIS_ID_ROOM_MISSING = 'BATTLETRIS_ID_ROOM_MISSING',
  CANNOT_KICK_ANOTHER_USER = 'CANNOT_KICK_ANOTHER_USER',
  CONNECTION_ID_ALREADY_REGISTERED = 'CONNECTION_ID_ALREADY_REGISTERED',
  CONNECTION_ID_INVALID = 'CONNECTION_ID_INVALID',
  CONNECTION_NOT_JOINED = 'CONNECTION_NOT_JOINED',
  GAME_NOT_STARTED = 'GAME_NOT_STARTED',
  MAX_USERS = 'MAX_USERS',
}

export default ErrorCodes;
